.contenedor{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;

    .title{
        width: 100%;
        margin-top: 50px;
        background-color: #364881;
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: row;

        .indicacion{
            width: 60%;
        }

        .evaluacion{
            width: 40%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .item1{
                width: 33.3%;
            }
            .item2{
                width: 33.3%;
            }
            .item3{
                width: 33.3%;
            }

            p{
                font-family: "Roboto";
                font-weight: 700;
                text-align: center;
                color: white;
            }
        }
    }

    .item{
        width: 100%;
        // background-color: #364881;
        display: flex;
        flex-direction: row;

        .indicacion{
            width: 60%;

            p{
                margin-left: 10px;
                font-family: "Roboto";
                color: #364881;
                font-weight: 500;
            }
        }

        .evaluacion{
            width: 40%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            .items_radio{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .itemM{
                width: 33.3%;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }

    }

    
    .item_par{
        width: 100%;
        background-color: #bcccff;
        display: flex;
        flex-direction: row;

        .indicacion{
            width: 60%;

            p{
                margin-left: 10px;
                font-family: "Roboto";
                color: #364881;
                font-weight: 500;
            }
        }

        .evaluacion{
            width: 40%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            .items_radio{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .itemM{
                width: 33.3%;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }

    }

    .totales{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 30px;
        background-color: #364881;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: row;

        .indicacion{
            width: 60%;

            p{
                text-align: center;
                font-family: "Roboto";
                color: white;
                font-weight: 500;
                font-size: 30px;
            }
        }

        .evaluacion{
            width: 40%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p{
                width: 100%;
                font-family: "Roboto";
                font-weight: 700;
                text-align: center;
                color: white;
                font-size: 30px;
            }
        }
    }

}